import { computed, onMounted, reactive, ref, Ref, toRaw } from "vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { DealearProps } from '@/views/Payment/Mobile/PendingApproval/type'
// import useMock from '@/utils/payment/useMock'
import useStripedRowClass from '@/utils/payment/useStripedRowClass'
// import MockTableData from '@/views/Payment/Mobile/PendingApproval/mockTableData'
import * as API from "@/API/payment";
import { useStore } from "vuex"
type Key = ColumnProps['key'];
const usePaymentApproval = () => {
  const store = useStore()
  console.log(window.screen.width)
  const _w = window.screen.width / 375
   
  const dealerColumn = ref([
    {
      title: "Payout Code",
      dataIndex: "payoutCode",
      slots: { customRender: 'payoutCode' },
      width: _w * 180,
    },
    {
      title: "w/o VAT",
      dataIndex: "payoutAmountWVoat",
      slots: { customRender: 'payoutAmountWVoat' },
      align: "right",
      width: _w * 130
    },
  ]);
  const AFCAndDLCColumn = ref([
    {
      title: "Payout Code",
      dataIndex: "payoutCode",
      slots: { customRender: 'payoutCode' },
      width: _w * 180,
    },
    {
      title: "w VAT",
      dataIndex: "payoutAmountWVat",
      slots: { customRender: 'payoutAmountWVat' },
      align: "right",
      width: _w * 130
    },
  ]);
  // const state = reactive({
  //   selectedRowKeys: ref<number[]>(), // Check here to configure the default column
  //   selectedRows: ref<number[]>(),
  //   status: '',
  //   approveComment:'',
  //   rejectComment:'',
  //   /* pageNumber:1,
  //   pageSize:10,*/
  //   userName: ''
  // })

  const dealerTableData = ref<any>()
  const getDealerTable = () => {
    // let userName = store.state.user.fulnm
    // if(userName.indexOf('Sales')!=-1){
    //   userName = 'OEM IT Admin'
    // }
    const params = {
      pageNumber: 1,
      pageSize: 10000,
      userId: store.state.user.usrid,
      typeList: []
    }
    API.getDealerPendingList(params).then((res) => {
        console.log('获取审批菜单下主页面数据-Dealer：',res)
        dealerTableData.value = res.content;
    });
    // const params = {
    //   userName,
    //   status: 'DEALER'
    // }
    // API.getApprovealMainTable(params).then((res) => {
    //   dealerTableData.value = res
    // })
  }
  const AFCAndDLCTableData = ref<any>()
  const getAFCAndDLCTable = () => {
    const params = {
      pageNumber: 1,
      pageSize: 10000,
      userId: store.state.user.usrid,
      typeList: []
    }
    API.getFinancePendingList(params).then((res) => {
        console.log('获取审批菜单下主页面数据-MBAFCandMBLC：',res)
        AFCAndDLCTableData.value = res.content;
    });
    // let userName = store.state.user.fulnm
    // if(userName.indexOf('Sales')!=-1){
    //   userName = 'OEM IT Admin'
    // }
    // const params = {
    //   userName,
    //   status: 'AFCANDLC'
    // }
    // API.getApprovealMainTable(params).then((res) => {
    //   AFCAndDLCTableData.value = res
    // })
  }

  onMounted(() => {
    setTimeout(() => {
      getDealerTable()
      getAFCAndDLCTable()
    }, 1000)
  })

  const total = computed(() => {
    const dealerTotal = dealerTableData.value ? dealerTableData.value.length : 0
    const AFCAndDLCTotal = AFCAndDLCTableData.value ? AFCAndDLCTableData.value.length : 0
    return dealerTotal + AFCAndDLCTotal
  })

  const dealerTableSelection = reactive<{
    selectedRowKeys: Key[];
    selectedRowData: DealearProps[];
    onChange: Function;
    columnWidth: string | number;
  }>({
    onChange: (selectedRowKeys: string[], selectedRows: DealearProps[]) => {
      dealerTableSelection.selectedRowKeys = selectedRowKeys
      dealerTableSelection.selectedRowData = selectedRows;
    },
    selectedRowKeys: [],
    selectedRowData: [],
    columnWidth: 0
  });
  const AFCAndDLCTableSelection = reactive<{
    selectedRowKeys: Key[];
    selectedRowData: DealearProps[];
    onChange: Function;
    columnWidth: string | number;
  }>({
    onChange: (selectedRowKeys: string[], selectedRows: DealearProps[]) => {
      AFCAndDLCTableSelection.selectedRowKeys = selectedRowKeys
      AFCAndDLCTableSelection.selectedRowData = selectedRows;
    },
    selectedRowKeys: [],
    selectedRowData: [],
    columnWidth: 0
  });
  
  const actionType = ref<string>("approve");
  const approvalModalVisible = ref<boolean>(false);
  const modalVisible = ref<boolean>(false);
  const modalMessage = ref<string>('')
  const showMessage = (message: string) => {
    modalMessage.value = message
    modalVisible.value = true
  }
  const clearSelection = () => {
    dealerTableSelection.selectedRowKeys = [];
    dealerTableSelection.selectedRowData = [];
    AFCAndDLCTableSelection.selectedRowKeys = [];
    AFCAndDLCTableSelection.selectedRowData = [];
  }
  const selectedRowKeys = computed(() => [...dealerTableSelection.selectedRowKeys, ...AFCAndDLCTableSelection.selectedRowKeys])
  const selectedRowData = computed(() => [...dealerTableSelection.selectedRowData, ...AFCAndDLCTableSelection.selectedRowData])
  const loading = ref(false)
  const handleCommentsConfirm = (comment: string, next: Function) => {
    if (loading.value === true) {
      return
    }
    const closeModal = () => {
      approvalModalVisible.value = false
      next()
      clearSelection()
    }

    const params = {
      rejectComment: '',
      approveComment: ''
    }
    loading.value = true
    if (actionType.value === 'reject'){
      API.fetchPaymentReject(selectedRowData.value.map(item => {
        return {
          rejectComment: comment,
          delegationUserId: item.delegationUserId,
          id: item.id,
          requestUserId: item.requestUserId
        }
      })).then((res) => {
        if (res.code == '0'){
          getDealerTable()
          getAFCAndDLCTable()
          closeModal()
          showMessage('Successfully Reject！')
          // Modal.info({
          //   title: 'Tips',
          //   content: 'Successfully Reject！'//res.data.message
          // })
        }
        else {
          closeModal()
          showMessage(res.message)
          // Modal.error({
          //   title: 'Tips',
          //   content: res.message
          // })
        }
        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    } else if (actionType.value === 'approve'){
      API.fetchPaymentApprove(selectedRowData.value.map(item => {
        return {
          approveComment: comment,
          delegationUserId: item.delegationUserId,
          id: item.id,
          requestUserId: item.requestUserId
        }
      })).then((res) => {
        if (res.code == '0'){
          getDealerTable()
          getAFCAndDLCTable()
          closeModal()
          showMessage('Successfully Approve！')
          // Modal.info({
          //   title: 'Tips',
          //   content: 'Successfully Approve！'//res.data.message
          // })
        }
        else {
          closeModal()
          showMessage(res.message)
          // Modal.error({
          //   title: 'Tips',
          //   content: res.message
          // })
        }
        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }
  }

  const getRowClass = useStripedRowClass<DealearProps>()
  return {
    total,
    dealerColumn,
    AFCAndDLCColumn,
    dealerTableSelection,
    AFCAndDLCTableSelection,
    selectedRowData,
    dealerTableData,
    AFCAndDLCTableData,
    actionType,
    approvalModalVisible,
    handleCommentsConfirm,
    modalVisible,
    modalMessage,
    loading
  };
};
export default usePaymentApproval;
