
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { HomeOutlined } from "@ant-design/icons-vue";
import MButton from "../Components/MButton.vue";
import MSegment from "../Components/MSegment.vue";
import MTitle from "../Components/MTitle.vue";
import MInfoModal from "../Components/MInfoModal.vue";
import MToTop from "@/views/Payment/Mobile/Components/MToTop.vue";
import ApprovalModal from "./approvalModal.vue";
import usePendingApproval from "@/hooks/payment/mobile/usePendingApproval";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import { LogoutOutlined } from "@ant-design/icons-vue";
import { outLogin } from "@/API/user";
import store from "@/store";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const router = useRouter();
    const {
      total,
      dealerColumn,
      AFCAndDLCColumn,
      dealerTableSelection,
      AFCAndDLCTableSelection,
      selectedRowData,
      dealerTableData,
      AFCAndDLCTableData,
      actionType,
      approvalModalVisible,
      handleCommentsConfirm,
      modalMessage,
      modalVisible,
      loading,
    } = usePendingApproval();
    const handleReject = () => {
      if (
        dealerTableSelection.selectedRowKeys.length === 0 &&
        AFCAndDLCTableSelection.selectedRowKeys.length === 0
      ) {
        message.warning("Please select the data first");
        return;
      }
      actionType.value = "reject";
      approvalModalVisible.value = true;
    };
    const handleApprove = () => {
      if (
        dealerTableSelection.selectedRowKeys.length === 0 &&
        AFCAndDLCTableSelection.selectedRowKeys.length === 0
      ) {
        message.warning("Please select the data first");
        return;
      }
      actionType.value = "approve";
      approvalModalVisible.value = true;
    };

    let startPoint = {
      x: 0,
      y: 0,
    };
    const dealerTouchStart = (event: any) => {
      startPoint = {
        x: event.changedTouches[0].pageX,
        y: event.changedTouches[0].pageY,
      };
    };
    const dealerToPage = (record: any, event: any) => {
      const nowPoint = {
        x: event.changedTouches[0].pageX,
        y: event.changedTouches[0].pageY,
      };
      if (
        Math.abs(nowPoint.x - startPoint.x) < 5 &&
        Math.abs(nowPoint.y - startPoint.y) < 5
      ) {
        router.push({
          path: "/paymentMobile/PayoutCodeDealer",
          query: {
            id: record.id,
            payoutCode: record.payoutCode,
            delegationUserId: record.delegationUserId,
            requestUserId: record.requestUserId,
            // wvat: record.payoutAmountWVat,
            // wvoat: record.payoutAmountWVoat
            // total: total.value
          },
        });
      }
    };
    const AFCAndDLCTouchStart = (event: any) => {
      startPoint = {
        x: event.changedTouches[0].pageX,
        y: event.changedTouches[0].pageY,
      };
    };
    const AFCAndDLCToPage = (record: any, event: any) => {
      const nowPoint = {
        x: event.changedTouches[0].pageX,
        y: event.changedTouches[0].pageY,
      };
      if (
        Math.abs(nowPoint.x - startPoint.x) < 5 &&
        Math.abs(nowPoint.y - startPoint.y) < 5
      ) {
        router.push({
          path: "/paymentMobile/PayoutCodeAFCLC",
          query: {
            id: record.id,
            payoutCode: record.payoutCode,
            // wvat: record.payoutAmountWVat,
            // wvoat: record.payoutAmountWVoat
            delegationUserId: record.delegationUserId,
            requestUserId: record.requestUserId,
            // total: total.value
          },
        });
      }
    };

    // 吸顶
    const isFixed = ref<boolean>(false);
    let offsetTop = 0;
    const fixedRef = ref<null | HTMLElement>(null);
    const pageRef = ref<null | HTMLElement>(null);
    const toTopShow = ref<boolean>(false);
    const initHeight = () => {
      const scrollTop = pageRef.value ? pageRef.value?.scrollTop : 0;
      isFixed.value = scrollTop > offsetTop;
      toTopShow.value = scrollTop > 0;
    };
    onMounted(() => {
      offsetTop = fixedRef.value ? fixedRef.value?.offsetTop : offsetTop;
      pageRef.value?.addEventListener("scroll", initHeight);
    });
    onUnmounted(() => {
      pageRef.value?.removeEventListener("scroll", initHeight);
    });
    let flag = true;
    const hanlderLogout = () => {
      const Authorization = store.state.Authorization;
      const params = {
        token: Authorization,
      };
      if (flag) {
        flag = false
        outLogin(params)
          .then((res) => {
            flag = true
            store.state.user.usrnm = "";
            store.commit("updateAuthorization", "");
            window.location.href = res;
          })
          .catch((err) => {
            flag = true
            message.error(err);
          });
      }
    };
    return {
      total,
      dealerColumn,
      AFCAndDLCColumn,
      dealerTableSelection,
      AFCAndDLCTableSelection,
      selectedRowData,
      dealerTableData,
      AFCAndDLCTableData,
      modalVisible,
      approvalModalVisible,
      actionType,
      handleReject,
      handleApprove,
      handleCommentsConfirm,
      dealerToPage,
      dealerTouchStart,
      AFCAndDLCToPage,
      AFCAndDLCTouchStart,
      modalMessage,
      isFixed,
      fixedRef,
      pageRef,
      toTopShow,
      toThousandsAndTwoDecimal,
      loading,
      hanlderLogout,
    };
  },
  components: {
    HomeOutlined,
    MButton,
    MSegment,
    MTitle,
    MToTop,
    ApprovalModal,
    MInfoModal,
    LogoutOutlined,
  },
});
